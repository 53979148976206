<template>
  <div id='cssmenu'>
      <div v-if="categoryLoading" class="text-center py-5">Loading Category...</div>
    <ul>
      <li
        v-for="(row, key) in category"
        :key="key"
        class="active has-sub"
      >
        <router-link :to="{ name: 'Category', params: { id: row.id, name: row.slug }}">{{row.name}} <i class="fa fa-chevron-right"></i></router-link>
        <ul v-if="row.childs">
          <li
            v-for="(srow, skey) in row.childs"
            :key="skey"
          >
            <div class="link">
              <router-link :to="{ name: 'Category', params: {id: srow.id, name: srow.slug }}"><span>{{srow.name}}</span></router-link>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categoryLoading: true,
      category: null,
    };
  },
  mounted() {
    this.$http
      .get("category")
      .then((response) => {
        this.category = response.data.data;
        this.categoryLoading = false;
      })
      .catch((e) => {
        this.category = [];
        this.categoryLoading = true;
      });
  },
};
</script>